import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import classHandler from "classnames";
import { ecollatHelper, svgIcon } from "../Functions";
import { HOST } from "../config";
import { GlobalSearchContext } from "../Components/Viewer/context";
import { generatePDFFromImages } from "../Functions/images";
import Service from "../Action/service";
import { ClickOnCustomMenuAnalatics, getDownloadTimeApi } from "../Action";
import axios from "axios";
import { ContactUsAnalatics, PdfDownloadAnalatics, PdfShareAnalatics } from "../Functions/analytic";

let hasProducts = !true;

const Header = (props) => {
  const navigate = useNavigate();
  const match = useParams();
  const { verticalscroll, isFlipBook, Sidebar, ismobile } = props.config;
  const [searcVal, setSearcVal] = useState("");
  const isoffline = localStorage.getItem("isoffline") === 'false' ? true : false
  const [netConn, setNetConn] = useState(isoffline);
  const [isReadOnly, setIsReadOnly] = useState(false);
  // const [isShowCart, setIsShowCart] = useState(
  //   JSON.parse(localStorage.getItem("isShowCart"))
  // );
  const [DownloadPDFloader, setDownloadPDFLoader] = useState(false);
  const searchInputRef = useRef();
  // const [_pdf, setNewPdf] = useState(null);
  let _pdf = null;

  const enablePopup = (type, data = {}) => {
    let popupConfig = {
      isActive: true,
      data,
      type,
    };

    props.togglePopup(popupConfig);
  };
  const [showIcons, setShowIcons] = useState({});

  const fetchEnableOrNot = async () => {
    try {
      let com_slug = window.__E_TT_Client.companySlug;
      const result = await Service.get(`public/getenableornot/${com_slug}`);
      if (result?.data?.success) {
        setShowIcons(result?.data?.data);
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchEnableOrNot();
  }, []);
  const handleCartToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { toggleCart, toggleSidebar } = props;
    toggleCart();
    if (toggleSidebar) toggleSidebar(false);
  };

  const handleSearchVal = (e) => {
    const searchValue = e.target.value;
    setSearcVal(searchValue);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searcVal.trim() === "") return;
    props.search(searcVal);
    setIsReadOnly(true);
  };

  const disableNavigation = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  const handleGoTo = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    if (!props.pdf) return;

    const { totalPages, pdfSlug } = props.pdf;

    if (
      e.which === 13 ||
      e.keyCode === 13 ||
      e.key === "Enter" ||
      e.key === 13
    ) {
      const pageNo = e.target.value;
      if (Number(pageNo) > totalPages || Number(pageNo) < 1) return false;
      let url = `/${pdfSlug}/page/${pageNo}`;
      if (verticalscroll) {
        url = `/${pdfSlug}/page/${pageNo}?scroll=true`;
      }
      navigate(url);
      e.target.value = "";
    }

    const code = e.which ? e.which : e.keyCode;
    const key = String.fromCharCode(code);
    const num = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    const result = num.includes(Number(key));

    if (!result) {
      e.preventDefault();
      return;
    }
  };

  const openSearch = () => {
    const searchInput = searchInputRef.current;
    if (searchInput) searchInput.focus();
    props.toggleSidebar(false);
    props.toggleSearch(true);
  };

  const closeSearch = () => {
    setSearcVal("");
    props.toggleSearch(false, true);
    setIsReadOnly(false);
  };

  const getSidebarToggler = () => {
    const { menuData, toggleSidebar, isSidebarOpen } = props;

    const clickfunction = () => {
      toggleSidebar();
      if (!isSidebarOpen) {
        ClickOnCustomMenuAnalatics({
          companySlug: window.__E_TT_Client.companySlug,
          pdfID: props.pdf.pdfID,
        });
      }
    };

    if (!props.cookiePolicyData) {
      if (!menuData || menuData.length <= 0) return null;
    } else if (props.isHomePage) return null;

    return (
      <span
        title="Menu"
        className={`nav-btn clickable toggle-sidebar ${isSidebarOpen && "bg-arrow"
          }`}
        onClick={clickfunction}
      >
        {props.isSidebar ? (
          <span className={isSidebarOpen ? "enabled" : ""}>
            {isSidebarOpen ? (
              <span children={svgIcon("ico-menu-back")} />
            ) : (
              <span children={svgIcon("ico-menu")} />
            )}
          </span>
        ) : (
          <span className={isSidebarOpen ? "enabled" : ""}>
            <span children={svgIcon("ico-menu-back")} />
            <span children={svgIcon("ico-menu")} />
          </span>
        )}
      </span>
    );
  };

  const getDownloadLink = () => {
    const { pdfSlug, pdfID, parent, showDownloadText, pages } = props.pdf || {};
    let link;
    if (parent) link = `${HOST}/viewsofpdf?id=${pdfID}&timestamp=${pdfSlug}`;
    else link = `${HOST}/pdfdownload/${pdfID}`;

    if (link) {
      return (
        <>
          {DownloadPDFloader ? (
            <div className="loader-container">
              <div className={`loaderingpdf`} />
            </div>
          ) : showDownloadText ? (
            <button
              id={`${props?.pdf?.showForm ? "" : "idico-download"}`}
              className="downdload-btn"
              onClick={() => downloadPDF(link, pdfSlug, pages)}
            >
              Download PDF
            </button>
          ) : (
            <>
              <button
                id="idico-download"
                className="downdload-btn"
                onClick={() => downloadPDF(link, pdfSlug, pages)}
                children={svgIcon("ico-download")}
              />
            </>
          )}
        </>
      );
    }
    return null;
  };

  const downloadPDF = async (url, pdfSlug, pages) => {
    if (pages) {
      setDownloadPDFLoader(true);
      await generatePDFFromImages(props.pdf.pdfID, pages).then(() => {
        setDownloadPDFLoader(false);
      });
    } else {
      const { showForm } = props.pdf;
      const data = {
        url,
        pdfSlug,
        pdfID: props.pdf.pdfID,
        isHeader: true,
        clientID: props.pdf.clientID,
      };

      const popupConfig = {
        isActive: true,
        data,
        type: "downloadPDF",
      };

      if (showForm) {
        // setDownloadPDFLoader(true);
        enablePopup("downloadPDF", popupConfig);
      } else {
        setDownloadPDFLoader(true);
        await Service.get(url).then((res) => {
          const url = res.data.data;

          let downloadStartTime;

          axios
            .get(url, {
              responseType: "blob",
              onDownloadProgress: () => {
                if (!downloadStartTime) {
                  downloadStartTime = new Date();
                }
              },
            })
            .then((response) => {
              const downloadEndTime = new Date();
              const downloadTimeInMilliseconds =
                downloadEndTime - downloadStartTime;
              getDownloadTimeApi({
                pdfID: data.pdfID,
                clientID: data.clientID,
                downTime: downloadTimeInMilliseconds,
              });
              setDownloadPDFLoader(false);

              const blob = new Blob([response.data], {
                type: "application/pdf",
              });
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.style.display = "none";
              a.href = url;
              a.download = pdfSlug;
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
              console.error("Error downloading PDF:", error);
              setDownloadPDFLoader(false);
            });
        });
      }
      PdfDownloadAnalatics({
        companySlug: window.__E_TT_Client.companySlug,
        pdfID: props.pdf.pdfID,
        isHeader: true,
        isForm: false
      })


    }
  };

  const toggleFullScreen = () => {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  };

  const getGotoPlaceHolder = () => {
    const { pdf } = props;
    let total = pdf ? pdf.totalPages : 0;
    let { pageNo } = match;
    pageNo = pdf ? _pdf.sanitizePageNo(pageNo) : pageNo;

    if (!pdf) return null;

    if (_pdf.spreadCount() === 1) {
      return props.isSidebar
        ? `${pageNo} - ${pageNo + 1}`
        : `${pageNo} / ${total}`;
    } else {
      if (pageNo === 1)
        return props.isSidebar
          ? `${pageNo} - ${total}`
          : `${pageNo} / ${total}`;
      else if (total % 2 === 0 && pageNo >= total)
        return props.isSidebar
          ? `${pageNo} - ${total}`
          : `${pageNo} / ${total}`;
      else
        return props.isSidebar
          ? `${pageNo} - ${pageNo + 1} - ${total}`
          : `${pageNo} - ${pageNo + 1} / ${total}`;
    }
  };

  const handleRemoveisLowrise = () => {
    localStorage.removeItem("isLowrise");
    localStorage.removeItem("pdf");

  };

  const getLogo = () => {
    const clinetInfo = window.__E_TT_Client;
    return (
      <div className="nav-logo" onClick={handleRemoveisLowrise}>
        {!clinetInfo.isHome ? (
          <Link to={"/"}>
            <img src={clinetInfo.logo} alt={clinetInfo.title} />
          </Link>
        ) : (
          <div className="isNOtShowHome">
            <img src={clinetInfo.logo} alt={clinetInfo.title} />
          </div>
        )}
      </div>
    );
  };
  const getHome = () => {
    const clinetInfo = window.__E_TT_Client;
    return (
      <div onClick={handleRemoveisLowrise}>
        {!clinetInfo.isHome ? (
          <Link to={"/"}>
            <span className="nav-btn" id="ico-Home">
              {svgIcon("ico-Home")}
            </span>
          </Link>
        ) : (
          <span className="nav-btn" id="ico-Home">
            {svgIcon("ico-Home")}
          </span>
        )}
      </div>
    );
  };
  const _sliderHeader = () => {
    const { isShowCartIcon } = useContext(GlobalSearchContext);
    const { isSearchOpen, menuData, toggleResult, searchLoading } = props;
    const hasSearch = props.pdf && props.pdf.search;
    // const { searcVal, netConn } = { searcVal, netConn };
    const hasParent = props.pdf && props.pdf.parent;
    const showDownloadIcon = props.pdf && props.pdf.lowrise;
    const { totalPages } = props.pdf || 0;
    let customPdfLocation = props?.location?.pathname?.includes("custom");
    const divClass = classHandler({
      "ecollat-search": true,
      "search-with-cart": isShowCartIcon,
      "mv-50": hasProducts || (menuData && menuData.length),
      "mv-100": hasProducts && menuData && menuData.length,
      newClassForSearchalign: true,
    });
    let isShowDownLoadButton = false;
    if (showDownloadIcon) {
      isShowDownLoadButton = true;
    } else if (customPdfLocation) {
      isShowDownLoadButton = true;
    } else {
      isShowDownLoadButton = false;
    }

    return (
      <>
        {props.isSidebar ? (
          <div
            className={`side-navigation   ${verticalscroll ? "site-header site-header-scroll" : "site-header"
              }`}
          >
            <div className="top-sidenav">
              {getHome()}
              <span className="nav-btn" id="ico-search">
                {svgIcon("ico-search")}
              </span>
              {/* <div
                className={`ecollat-search ${!hasProducts && "fluid"}`}
                style={{ position: "relative" }}
              >
                <div
                  className={isSearchOpen ? "search-open" : "search-close"}
                  style={{ position: "absolute", left: 0 }}
                >
                  <span
                    children={svgIcon("ico-search")}
                    className="open-search"
                    onClick={openSearch}
                  />
                  <input
                    type="search"
                    ref={searchInputRef}
                    value={searcVal}
                    onChange={handleSearchVal}
                    onKeyUp={disableNavigation}
                    onFocus={() => toggleResult(true)}
                    onClick={() => setIsReadOnly(false)}
                    readOnly={isReadOnly}
                  />
                  <span
                    children={svgIcon("ico-close")}
                    className={`close-search ${
                      searchLoading ? "is-loading" : ""
                    }`.trim()}
                    onClick={closeSearch}
                  />
                </div>
              </div> */}
              {!hasParent && getSidebarToggler()}
              {netConn && isShowCartIcon && (
                <span
                  className="nav-btn clickable marginRightMobile"
                  onClick={handleCartToggle}
                >
                  <span children={svgIcon("ico-cart")} />
                  <span className="cart-counter">
                    {props.cartCounter == 0 ? "" : props.cartCounter}
                  </span>
                </span>
              )}
            </div>
            <div className="middle-sidenav">
              {/* <span className="goto-Handler">
                     <input
                       type="tel"
                       placeholder={getGotoPlaceHolder()}
                       onKeyPress={handleGoTo}
                       onKeyUp={disableNavigation}
                     />
                   </span> */}
              <h5> {totalPages} </h5>
              <h5> of </h5>
              <div>
                <input
                  type="tel"
                  placeholder={getGotoPlaceHolder()}
                  onKeyPress={handleGoTo}
                  onKeyUp={disableNavigation}
                />
              </div>
            </div>
            <div className="bottom-sidenav">
              {props?.pdf?.lowrise && (
                <span
                  id={props?.pdf?.showDownloadText ? "" : "idico-download"}
                  className="nav-btn"
                  children={getDownloadLink()}
                />
              )}
              {showIcons?.showShare && (
                <span
                  className="nav-btn "
                  onClick={async () => {
                    enablePopup("share");
                    await Service.post(`public/shareclick`, {
                      companySlug: window.__E_TT_Client.companySlug,
                      pdfID: props.pdfID,
                      isHome: props.isHome,
                    });
                    PdfShareAnalatics({
                      companySlug: window.__E_TT_Client.companySlug,
                      pdfID: props.pdfID,
                    })
                  }}
                  id="idico-share"
                >
                  {svgIcon("ico-share")}
                </span>
              )}
              {showIcons?.showContact && (
                <span
                  className="nav-btn"
                  onClick={async () => {
                    enablePopup(
                      "contact",
                      props.pdf.contactInfo,
                      props.socialMediaLinks
                    );
                    ContactUsAnalatics({
                      companySlug: window.__E_TT_Client.companySlug,
                    })
                    await Service.post(`public/clickoncontactus`, {
                      companySlug: window.__E_TT_Client.companySlug,
                    });

                  }}
                  id="idico-contact"
                >
                  {svgIcon("ico-contact")}
                </span>
              )}
              <span
                className="nav-btn "
                onClick={toggleFullScreen}
                children={svgIcon("ico-fullscreen")}
              />
            </div>
          </div>
        ) : (
          <header
            className={`${verticalscroll ? "site-header site-header-scroll" : "site-header"
              }`}
          >
            {getLogo()}

            {netConn && (
              <>
                <div className="right-nav newRightNavAddCss">
                  {hasSearch && !props.Generatedpdf && (
                    <div className={divClass}>
                      <form
                        autoCapitalize="off"
                        className="trackSearchAnalytics"
                        autoComplete="off"
                        spellCheck="off"
                        onSubmit={handleSearch}
                      >
                        <div
                          className={
                            isSearchOpen ? "search-open" : "search-close"
                          }
                        >
                          <span
                            children={svgIcon("ico-search")}
                            className="open-search"
                            onClick={openSearch}
                          />
                          <input
                            type="search"
                            ref={searchInputRef}
                            value={searcVal}
                            onChange={handleSearchVal}
                            onKeyUp={disableNavigation}
                            onFocus={() => toggleResult(true)}
                            onClick={() => setIsReadOnly(false)}
                            readOnly={isReadOnly}
                          />
                          <span
                            children={svgIcon("ico-close")}
                            className={`close-search ${searchLoading ? "is-loading" : ""
                              }`.trim()}
                            onClick={closeSearch}
                          />
                        </div>
                      </form>
                    </div>
                  )}
                  {isShowCartIcon && netConn && (
                    <span
                      className="nav-btn clickable marginRightMobile"
                      onClick={handleCartToggle}
                    >
                      <span children={svgIcon("ico-cart")} />
                      <span className="cart-counter">
                        {props.cartCounter == 0 ? "" : props.cartCounter}
                      </span>
                    </span>
                  )}
                  {!hasParent && getSidebarToggler()}
                </div>
              </>
            )}

            <div className="center-nav newCenterNav">
              {props?.pdf?.lowrise && (
                <span
                  id={props?.pdf?.showDownloadText ? "" : "idico-download"}
                  className="nav-btn"
                  children={getDownloadLink()}
                />
              )}

              <span className="goto-Handler">
                <input
                  type="tel"
                  placeholder={getGotoPlaceHolder()}
                  onKeyPress={handleGoTo}
                  onKeyUp={disableNavigation}
                />
              </span>

              <span
                className="nav-btn clickable"
                onClick={toggleFullScreen}
                children={svgIcon("ico-fullscreen")}
              />
            </div>
          </header>
        )}
      </>
    );
  };

  const _pdfGenerateHeader = () => {
    return (
      <header
        className={`${verticalscroll ? "site-header site-header-scroll" : "site-header"
          }`}
      >
        {getLogo()}

        <div className="right-nav">
          <span
            className="nav-btn clickable"
            onClick={props.togglePdfGenerate}
            children={svgIcon("ico-close")}
          />
        </div>
      </header>
    );
  };
  // Default Header only logo and cart
  const _defaultHeader = () => {
    const { isSearchOpen, menuData, toggleResult, searchLoading, hasSearch } =
      props;
    return (
      <header
        className={`${verticalscroll ? "site-header site-header-scroll" : "site-header"
          }`}
      >
        {getLogo()}

        <div className="right-nav">
          {hasSearch && netConn && !props.Generatedpdf && (
            <div className={`ecollat-search ${!hasProducts && "fluid"}`}>
              <form
                autoCapitalize="off"
                autoComplete="off"
                spellCheck="off"
                onSubmit={handleSearch}
              >
                <div className={isSearchOpen ? "search-open" : "search-close"}>
                  <span
                    children={svgIcon("ico-search")}
                    className="open-search"
                    onClick={openSearch}
                  />
                  <input
                    type="search"
                    ref={searchInputRef}
                    value={searcVal}
                    onChange={handleSearchVal}
                    onKeyUp={disableNavigation}
                    onFocus={() => toggleResult(true)}
                  />
                  <span
                    children={svgIcon("ico-close")}
                    className={`close-search ${searchLoading ? "is-loading" : ""
                      }`.trim()}
                    onClick={closeSearch}
                  />
                </div>
              </form>
            </div>
          )}

          {getSidebarToggler()}
        </div>
      </header>
    );
  };

  const _render = () => {
    const { headerType, pdf } = props;

    if (!_pdf && pdf) {
      _pdf = new ecollatHelper(pdf);
      // setNewPdf(_pdf);
    }
    if (!props.embededlink) {
      switch (headerType) {
        case "slider":
          return _sliderHeader();

        case "generate-pdf":
          return _pdfGenerateHeader();

        default:
          return _defaultHeader();
      }
    }
  };

  // useEffect(() => {
  //   const handleOnline = () => {
  //     setNetConn(true);
  //     localStorage.setItem("isoffline", "false");
  //   };
  //   const handleOffline = () => {

  //     setNetConn(false);
  //     localStorage.setItem("isoffline", "true");
  //     closeSearch();
  //   };
  //   window.addEventListener("online", handleOnline);
  //   window.addEventListener("offline", handleOffline);

  //   return () => {
  //     window.removeEventListener("online", handleOnline);
  //     window.removeEventListener("offline", handleOffline);
  //   };
  // }, []);
  let interval = null;
  const InternetErrMessagenger = () => {
    setNetConn(navigator.onLine === true);
    localStorage.setItem("isoffline", navigator.onLine === true ? false : true);
  }


  useEffect(() => {
    InternetErrMessagenger()
    interval = setInterval(InternetErrMessagenger, 600000); // call the function name only not with function with call `()`
    return () => {
      clearInterval(interval) // for component unmount stop the interval
    }
  }, [])

  return _render();
};
export default Header;
