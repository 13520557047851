/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from "react";
import { CartContext, GlobalSearchContext } from "./context";
import { Layout, Header } from "../../App";
import Viewer from "./ecollat-viewer";
import Sidebar from "./sidebar";
import SearchResult from "./searchResult";
import BottomNavigation from "./bottom-navigation";
import Generate from "./Generator";
import { Popup } from "../../Containers";
import {
  getPdfDetails,
  getPdfDetailsAnalatics,
  getPdfMenu,
  getPdfProdcuts,
  searchInEcollat,
} from "../../Action";
import productHandler from "./../../Functions/product-handler";
import SpinnerLoader from "../../Common/SpinnerLoader";
import { useLocation, useParams } from "react-router-dom";
import { CustomPdfInitAnalatics, CustomPdfOpenAnalatics, HomePageView, VisitPDF } from "../../Functions/analytic";

const Slider = (props) => {
  const [loading, setLoading] = useState(true);
  const [ecollat, setEcollat] = useState(null);
  // const [slug, setSlug] = useState(null);
  const [error, setError] = useState(false);
  const [linkData, setLinkData] = useState([]);
  const [menuData, setMenuData] = useState(null);
  // const [openBanner, setOpenBanner] = useState(false);
  const [productData, setProductData] = useState(null);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [showPopup, setShowPopup] = useState({
    isActive: false,
    data: null,
    type: null,
  });
  const [searchResult, setSearchResult] = useState(null);
  const [enablePdfGenerate, setEnablePdfGenerate] = useState(false);
  const [cartCounter, setCartCounter] = useState(0);
  const [embededlink, setEmbededlink] = useState(true);
  const [embedConfig, setembedConfig] = useState({
    embededlink: false,
    isTransparent: false
  })
  const [Generatedpdf, setGeneratedpdf] = useState(false);
  const globalSearchData = useContext(GlobalSearchContext);
  const { setIsShowCartIcon } = useContext(GlobalSearchContext);

  const viewref = useRef();
  const [zoomActive, setZoomActive] = useState(false);
  const match = useParams();
  const location = useLocation();

  const [ismobile, setismobile] = useState(false);
  var isSidebar = false; // Temprary for sidebar

  const fetchCartCounterData = async () => {
    const PRODUCT = new productHandler(null);
    const res = await PRODUCT.CartData();
    if (res.data) {
      setCartCounter(res.data.length);
    }
  };

  const fetchPdfDetils = async (navState, params) => {
    getPdfDetailsAnalatics(params.pdf_slug)
    if (navState && navState.pdfData) {
      setEcollat(navState.pdfData);
      // setSlug(navState.pdfSlug);
      VisitPDF({
        clientID: navState.pdfData.clientID,
        pdfID: navState.pdfData.pdfID
      })
      return navState.pdfData;

    } else if (params && params.pdf_slug) {
      const ecollat = await getPdfDetails(params.pdf_slug);
      HomePageView({
        clientID: ecollat.clientID,
        pdfId: ecollat.pdfID,
        isHome: false,
        scroll: true,
      })
      VisitPDF({
        clientID: ecollat.clientID,
        pdfID: ecollat.pdfID
      })

      if (ecollat && typeof ecollat === "object") {
        setEcollat(ecollat);
        if (window.__E_TT_Client.isHome) {
          if (ecollat && ecollat.showCart) {
            localStorage.setItem("isShowCart", ecollat && ecollat?.showCart);
            setIsShowCartIcon(ecollat?.showCart)
          }
        }
        // setSlug(ecollat.pdfSlug);
        return ecollat;
      } else return false;
    } else return false;
  };
  const fetchPdfJsonData = async (pdfId) => {
    const clientInfo = window.__E_TT_Client;
    const data = await getPdfMenu(pdfId, clientInfo.companySlug);
    const linkDataRes = data;
    const menuDataRes = data;
    const productData = await getPdfProdcuts(pdfId);
    let menuData;

    menuData =
      menuDataRes && menuDataRes.menu_data
        ? JSON.parse(menuDataRes.menu_data)
        : null;
    const linkData =
      linkDataRes && linkDataRes.link_data
        ? JSON.parse(linkDataRes.link_data)
        : [];
    setLinkData(linkData);
    setMenuData(menuData);
    setProductData(productData);
    setLoading(false);
    return false;
  };

  const search = async (term) => {
    if (!ecollat || !term) return false;

    setSearchLoading(true);
    let res = await searchInEcollat(term, ecollat.pdfID, ecollat.pages);
    setSearchLoading(false);
    setSearchResult(res);
    setShowResult(true);
    setTimeout(() => updateSliderCalc(), 250);
  };

  const _renderLoading = (showError = false) => {
    return (
      <div className="wrap">
        <div className="loader">{!embededlink && <SpinnerLoader />}</div>
      </div>
    );
  };

  const updateSliderCalc = () => {
    const view = viewref.current;
    if (view && view.calcSlider) view.calcSlider();
  };

  const toggleSidebar = (value) => {
    setShowSidebar((prev) => (typeof value === "boolean" ? value : !prev));
    setShowSearch(false);
    setSearchResult(null);
    setTimeout(() => updateSliderCalc(), 250);
  };

  const toggleSearch = (value, clearSearch) => {
    if (clearSearch) {
      setShowSearch(false);
      setSearchResult(null);
      setShowResult(false);
      setTimeout(() => updateSliderCalc(), 250);
    } else {
      setShowSearch((prev) => (typeof value === "boolean" ? value : !prev));
      setShowSidebar(false);
    }
  };

  const toggleResult = (value) => {
    if (window.innerWidth < window.innerHeight) {
      setShowResult((prev) => (typeof value === "boolean" ? value : !prev));
    }
  };

  const togglePopup = (config, closePopup = false) => {
    if (closePopup) {
      let defaultConfig = {
        isActive: false,
        data: null,
        type: null,
      };
      return setShowPopup(defaultConfig);
    }
    setShowPopup(config);
  };

  const productdetail = (config) => {
    // your implementation
  };

  const togglePdfGenerate = () => {
    if (ecollat !== null && ecollat) {
      CustomPdfInitAnalatics({
        companySlug: window.__E_TT_Client.companySlug,
        pdfID: ecollat.pdfID
      })
    }
    setEnablePdfGenerate((prev) => !prev);
  };
  async function fetchdata() {
    const searchParams = new URLSearchParams(window.location.search);
    const embedValue = searchParams.get("_embed");
    const _transparent = searchParams.get("_transparent");
    const isTransparent = _transparent === "true" ? true : false;
    const embededlink = embedValue === "embedded" ? false : true;

    if (isTransparent) {
      document.body.style.backgroundColor = 'transparent';
    }


    setEmbededlink(embededlink);
    setembedConfig({
      ...embedConfig,
      embededlink: embededlink,
      isTransparent: isTransparent
    })
    // setTimeout(() => {
    //   setOpenBanner(true);
    // }, 2000);

    const { state } = location;
    const params = match;
    const pdfRes = await fetchPdfDetils(state, params);
    if (!pdfRes) return setError(true);

    let _result,
      _globalSearchData = globalSearchData.searchResult;
    // eslint-disable-next-line eqeqeq
    if (_globalSearchData != undefined) {
      for (let i = 0; i < _globalSearchData.length; i++) {
        if (_globalSearchData[i].pdf.pdfID === pdfRes.pdfID) {
          _result = _globalSearchData[i].data;
          break;
        }
      }
      setSearchResult(_result);
    }
    await fetchPdfJsonData(pdfRes.pdfID);
  }
  useEffect(() => {
    const url = window.location.href;

    // Define a regular expression pattern to extract the desired value
    const regex = /\/([^/]+)\/page\/\d+/;

    // Use the exec method to extract the matched value
    const match = regex.exec(url);
    // Check if there is a match and get the value
    const starting5 = match ? match[1].slice(0, 6) : null;
    if (starting5 === "custom") {
      setGeneratedpdf(true);
      CustomPdfOpenAnalatics({
        url: url,
        companySlug: window.__E_TT_Client.companySlug,
      })
    }
    fetchdata();
    fetchCartCounterData();
  }, []); // empty dependency array to run this effect only once on mount
  const _render = () => {
    if (error || loading) return _renderLoading(error);
    return (
      <Viewer
        {...props}
        ref={viewref}
        config={{
          verticalscroll:
            ecollat?.verticalScroll && ecollat?.verticalScroll !== null
              ? ecollat?.verticalScroll
              : false,
          isFlipBook:
            ecollat?.isFlip && ecollat?.isFlip !== null
              ? ecollat?.isFlip
              : false,
          isSidebar: isSidebar,
          ismobile: ismobile,
        }}
        setismobile={setismobile}
        isSidebar={ismobile ? false : isSidebar}
        embededlink={!embededlink}
        embedConfig={embedConfig}
        pdf={ecollat}
        hotSpot={linkData}
        products={productData}
        searchActive={searchResult && showSearch ? true : false}
        searchResult={searchResult}
        togglePopup={togglePopup}
        setZoomActive={setZoomActive}
        zoomActive={zoomActive}
        productdetail={productdetail}
        socialMediaLinks={props.socialMediaLinks}
        match={{ params: match }}
      />
    );
  };
  return (
    <CartContext.Provider
      value={{
        fetchCartCounterData: fetchCartCounterData,
      }}
    >
      <Layout
        togglePopup={togglePopup}
        pdf={ecollat}
        whiteBG
        isSidebar={ismobile ? false : isSidebar}
        config={{
          verticalscroll:
            ecollat?.verticalScroll && ecollat?.verticalScroll !== null
              ? ecollat?.verticalScroll
              : false,
          isFlipBook:
            ecollat?.isFlip && ecollat?.isFlip !== null
              ? ecollat?.isFlip
              : false,
          isSidebar: isSidebar,
          ismobile: ismobile,
        }}
        embedConfig={embedConfig}
      >
        <Header
          {...props}
          headerType={enablePdfGenerate ? "generate-pdf" : "slider"}
          embededlink={!embededlink}
          embedConfig={embedConfig}
          pdf={ecollat}
          config={{
            verticalscroll:
              ecollat?.verticalScroll && ecollat?.verticalScroll !== null
                ? ecollat?.verticalScroll
                : false,
            isFlipBook:
              ecollat?.isFlip && ecollat?.isFlip !== null
                ? ecollat?.isFlip
                : false,
            isSidebar: isSidebar,
            ismobile: ismobile,
          }}
          ismobile={ismobile}
          isSidebar={ismobile ? false : isSidebar}
          Generatedpdf={Generatedpdf}
          menuData={menuData}
          toggleSidebar={toggleSidebar}
          toggleResult={toggleResult}
          toggleSearch={toggleSearch}
          togglePdfGenerate={togglePdfGenerate}
          search={search}
          isSidebarOpen={showSidebar}
          isSearchOpen={showSearch}
          searchLoading={searchLoading}
          cartCounter={cartCounter}
          togglePopup={togglePopup}
        />

        {_render()}

        <Sidebar
          ismobile={ismobile}
          isSidebar={ismobile ? false : isSidebar}
          embededlink={embededlink}
          embedConfig={embedConfig}
          {...props}
          pdf={ecollat}
          menuData={menuData}
          toggleSidebar={toggleSidebar}
          isSidebarOpen={showSidebar}
          toggleGenerate={togglePdfGenerate}
          togglePopup={togglePopup}
        />

        <BottomNavigation
          ismobile={ismobile}
          isSidebar={ismobile ? false : isSidebar}
          Generatedpdf={Generatedpdf}
          embededlink={embededlink}
          embedConfig={embedConfig}
          {...props}
          pdf={ecollat}
          pdfID={ecollat?.pdfID}
          isHome={false}
          isSidebarOpen={showSidebar}
          toggleGenerate={togglePdfGenerate}
          togglePopup={togglePopup}
          hideDownloadHome={false}
          socialMediaLinks={props.socialMediaLinks}
        />

        <SearchResult
          ismobile={ismobile}
          isSidebar={ismobile ? false : isSidebar}
          pdf={ecollat}
          result={searchResult}
          closeSearch={toggleResult}
          isSearchOpen={showResult}
        />

        <Generate
          ismobile={ismobile}
          isSidebar={ismobile ? false : isSidebar}
          toggle={togglePdfGenerate}
          isActive={enablePdfGenerate}
          pdf={ecollat}
          shareLink={togglePopup}
        />

        <Popup
          ismobile={ismobile}
          isSidebar={ismobile ? false : isSidebar}
          config={showPopup}
          close={() => togglePopup({}, true)}
          fetchCartCounterData={fetchCartCounterData}
        />
      </Layout>
    </CartContext.Provider>
  );
};

export default Slider;
