import { set as setDB, get as getDB } from "idb-keyval";
import { ASSETS_URI, ECOLLAT_CACHE, HOST, PDF_DB } from "../config";
import { getAllPdf } from "./index";
import Service from "./service";
import { PwaAnalatics } from "../Functions/analytic";

const User = window.__E_TT_Client;
const client = User.companySlug;
const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

let progressBar;
let timeout;
let processCount = 0;

const updateProgressBar = (barCount, removeBar = false, showError = false) => {
  if (!progressBar) {
    const root = document.getElementsByTagName("body")[0];

    const loadingBar = document.createElement("div");
    loadingBar.classList.add("pwa-progressbar");

    const fillBar = document.createElement("span");
    fillBar.classList.add("pwa-install-progress");
    loadingBar.appendChild(fillBar);

    progressBar = {
      bar: loadingBar,
      progress: fillBar,
    };

    root.appendChild(loadingBar);
  }

  if (progressBar.progress) {
    progressBar.progress.style.width = `${barCount}%`;
  }

  if (showError && progressBar.progress) {
    progressBar.progress.classList.add("pwa-error");
  }

  if (removeBar) {
    timeout = setTimeout(() => {
      clearTimeout(timeout);
      if (progressBar.bar) {
        progressBar.bar.remove();
        processCount = 0;
      }
    }, 2000);
  }
};
// Function to make a POST request
async function makePostRequest(url, data) {
  try {
    const response = Service.post(url, data, {
      headers: {
        "Content-Type": "application/json",
        // Add any other headers if needed
      },
    });

    // Axios automatically throws an error for non-2xx responses
    const jsonResponse = response.data;
    return jsonResponse;
  } catch (error) {
    console.error("Error making POST request:", error);
    throw error;
  }
}
export const getAllImagesUri = (pdfs) => {
  const size = window.innerWidth > 768 ? 0 : 1;

  if (pdfs && pdfs.length > 0) {
    let imgToCache = [];

    imgToCache.push(`${HOST}/allpagesforimages/pages/${window.__E_TT_Client.companySlug}`);
    pdfs.map(async (pdf) => {
      for (let i = 1; i <= pdf.totalPages; i++) {
        imgToCache.push(
          `${ASSETS_URI}/${window.__E_TT_Client.companySlug}/${pdf.pdfID}/${i}/image-${size}.jpg`
        );
        // imgToCache.push(`${HOST}/view-image.dll?id=${pdf.pdfID}&count=${i}&imagesize=${size}`);
      }
      // imgToCache.push(response);
      // imgToCache.push(`${HOST}/searchaccordingtoslug/${pdf.pdfSlug}`);
      // imgToCache.push(`${HOST}/${client}/${pdf.pdfID}/Links/menu.json`);
      // imgToCache.push(`${HOST}/getlinks/${pdf.pdfID}`);
      imgToCache.push(pdf.pdfcoverPath);
      return pdf;
    });
    return imgToCache;
  }
  return [];
};

export const updatePdfsOffline = async (pdfs) => {
  try {
    let oldPdf = await getDB(PDF_DB).then((res) => res && JSON.parse(res));

    oldPdf = oldPdf && oldPdf.length > 0 ? oldPdf.map((p) => p.pdfID) : [];
    let pdfToOffline = pdfs && pdfs.length > 0 ? pdfs : [];

    if (oldPdf && oldPdf.length > 0 && pdfs && pdfs.length > 0) {
      pdfToOffline = pdfs.filter((p) => !oldPdf.includes(p.pdfID));
    }

    const imageToCache = getAllImagesUri(pdfToOffline);
    await cacheImages(imageToCache);

    if (pdfs) {
      setDB(PDF_DB, JSON.stringify(pdfs));
    }
  } catch (e) {
  }
};

export const PedfVersionRemoveCache = async (pdfs) => {
  try {
    let oldPdf = await getDB(PDF_DB).then((res) => res && JSON.parse(res));

    oldPdf = oldPdf && oldPdf.length > 0 ? oldPdf.map((p) => p) : [];
    let pdfToOffline = pdfs && pdfs.length > 0 ? pdfs : [];

    // if (oldPdf && oldPdf.length > 0 && pdfs && pdfs.length > 0) {
    //   pdfToOffline = pdfs.filter((p) => !oldPdf.includes(p.pdfID));
    // }
    var pdfall = []
    pdfs.map(async item => {
      const pdf = oldPdf.filter(p => p.pdfID === item.pdfID)

      if (pdf.length > 0) {
        if (pdf[0].pdfVersion !== item.pdfVersion) {
          pdfall.push(item)
        }
      } else {
        pdfall.push(item)
      }

    })




    const imageToCache = getAllImagesUri(pdfall);
    await RemovecacheImages(imageToCache);

    if (pdfs) {
      setDB(PDF_DB, JSON.stringify(pdfs));
    }
  } catch (e) {
  }
};
export const RemovecacheImages = async (imageUris = false, savePdfData = false) => {
  let pdfs;
  if (!imageUris) {
    pdfs = await getAllPdf(client, false);
    imageUris = getAllImagesUri(pdfs, client);
  }

  if (imageUris.length === 0) return true;

  try {
    const cache = await caches.open(ECOLLAT_CACHE).catch((e) => false);

    if (cache && imageUris) {
      let syncStatus = await Promise.all(
        imageUris.map(async (url) => {
          let req = new Request(url);
          let cacheResponse = await caches.match(req);

          if (cacheResponse) {
            processCount++;
            await cache.delete(req);
            return true;
          }
          return false;
        })
      );
      if (syncStatus.filter((e) => !e).length > 7) {
        // throw new Error("Few errors found while syncing");
      }
    }

    if (pdfs && savePdfData) {
      setDB(PDF_DB, JSON.stringify(pdfs));
    }

    return true;
  } catch (e) {
    // alert("An error has occurred, please reinstall the app.");
    return e;
  }
};


export const cacheImages = async (imageUris = false, savePdfData = false) => {
  let pdfs;
  if (!imageUris) {
    pdfs = await getAllPdf(client, false);
    imageUris = getAllImagesUri(pdfs, client);
  }

  if (imageUris.length === 0) return true;

  try {
    const cache = await caches.open(ECOLLAT_CACHE).catch((e) => false);

    if (cache && imageUris) {
      let syncStatus = await Promise.all(
        imageUris.map(async (url) => {
          let req = new Request(url);
          let res = await fetch(req).catch((e) => {
            return false;
          });

          if (res) {
            processCount++;
            cache.put(req, res);
            updateProgressBar(processCount);
            return true;
          }
          return false;
        })
      );

      if (syncStatus.filter((e) => !e).length > 7) {
        throw new Error("Few errors found while syncing");
      }
    }

    if (pdfs && savePdfData) {
      setDB(PDF_DB, JSON.stringify(pdfs));
    }

    updateProgressBar(100, true);
    if (iOS) {
      alert("Successfully synced.");
    } else {
      alert("Successfully synced.");
    }
    return true;
  } catch (e) {
    updateProgressBar(processCount, true, true);
    alert("An error has occurred, please reinstall the app.");
    return e;
  }
};

window.addEventListener("appinstalled", (event) => {

  debugger


  PwaAnalatics({
    companySlug: window.__E_TT_Client.companySlug,
    isMobile: window.innerWidth > 768 ? false : true
  })






  cacheImages(false, true).then((res) => {
  });
});
